import Marionette from 'backbone.marionette';
import AgentResultHeaderActionsView
    from 'views/agents/agentResultHeaderActionsView';
import AgentResultDownload from 'models/agentResultDownload';
import Usage from 'models/usage';
import UserAgentResultPresenter from 'models/presenters/userAgentResultPresenter';
import RtvPriceOfNextUnit from 'models/rtvPriceOfNextUnit';
import Analytics from 'libraries/analytics';
import RecordingOrder from 'libraries/recordingOrder';
import OnlineClipOrder from 'libraries/onlineClipOrder';
import Notificator from 'libraries/notificator';
import i18n from 'i18n';
import Shortcutter from 'libraries/shortcutter';
import when from 'when';
import vent from 'libraries/vent'
import session from 'models/sessionInstance';

class AgentResultHeaderActionsController extends Marionette.Controller {
    initialize(options) {
        this.agentResult = options.agentResult;
        this.showTimeline2 = session.timeline2Enabled();
        this._initializeView();
        this.agentResultDownload = new AgentResultDownload({
            agentResult: this.agentResult
        });
        this._registerListeners();
        this._bindShortcuts();
    }

    _initializeView() {
        this.presenter = new UserAgentResultPresenter(this.agentResult);
        this.view = new AgentResultHeaderActionsView({
            model: this.agentResult,
            presenter: this.presenter
        });
    }

    onClose() {
        this._unbindShortcuts();
    }

    _registerListeners() {
        this.listenTo(this.view, "clicked:download", () => {
            this._downloadPdf();
        });
        this.listenTo(this.view, 'click:deleteResult', () => {
            this.agentResult.destroy();
            vent.trigger('agentResult:delete');
        });
        this.listenTo(this.view, 'click:translate', () => {
            this._openTranslateLink();
        });
        this.listenTo(this.view, 'click:cut', () => {
            this._openCuttingLink();
        });
        this.listenTo(this.view, 'click:editAgentResult', () => {
            this.trigger('edit-form:start');
        });
        this.listenTo(this.view, 'click:editClip', () => {
            this.trigger('clip-edit-form:start');
        });
        this.listenTo(this.view, 'click:rtvOrder', () => {
            this._verifyUsageLimit();
        });
        this.listenTo(this.view, 'click:onlineOrder', () => {
            this._displayOnlineClipOrderModal();
        });
        this.listenTo(this.view, 'modal:rtvOrderConfirm',
            this._recordingOrder
        );
        this.listenTo(this.view, 'modal:onlineClipOrderConfirm',
            this._orderOnlineClip
        );
        this.listenTo(this.view, 'clicked:pdf:report', this.handleReport('Medienspiegel'));
        this.listenTo(this.view, 'clicked:excel:report', this.handleReport('ExcelReport'));
        this.listenTo(this.view, 'clicked:newsletter:report', this.handleReport('Newsletter'));
    }

    _downloadPdf() {
        this.agentResultDownload.downloadPDF();
        Analytics.sendEvent(
            'br_agent_result_downloaded',
            {kind: this.agentResult.get('kind')}
        );
    }

    _verifyUsageLimit() {
        let self = this;
        when.all(
            [new Usage().load(), new RtvPriceOfNextUnit().load()]
        ).then(
            function (results) {
                let usageResult    = results[0];
                let nextUnitResult = results[1];

                if (usageResult.rtv_recording_usage.usage >=
                    usageResult.rtv_recording_usage.limit) {
                    self._displayNoQuotaAvailableModal();
                } else {
                    self._displayRtvOrderModal(
                        nextUnitResult.price_of_next_unit
                    );
                }
            },
            self._orderError
        );
    }

    _displayNoQuotaAvailableModal() {
        this.view.showNoquotaModal();
    }

    _displayRtvOrderModal(upgradePrice) {
        this.view.showRtvOrderModal(upgradePrice);
    }

    _displayOnlineClipOrderModal() {
        this.view.showOnlineClipOrderModal();
    }

    _recordingOrder(notes) {
        let self = this;
        RecordingOrder.order(this.agentResult.id, notes).then(
            function () {
                let message = i18n.t(
                    'webapp.notifications.agent_results.order_successful'
                );
                Notificator.showNotification(message);
                self.agentResult.set({ ordered: true });
                self.view.render();
            },
            function () {
                self._orderError();
            }
        );
    }

    _orderOnlineClip() {
        let _this = this;
        OnlineClipOrder.order(this.agentResult.id).then(
            function () {
                let message = i18n.t(
                    'webapp.agents.results.order_full_text.order_successful'
                );
                Notificator.showNotification(message);
            },
            function () {
                _this._orderError();
            }
        );
    }

    _orderError() {
        let message = i18n.t("webapp.notifications.error.order_failed");
        Notificator.showNotification(message);
    }

    _openTranslateLink() {
        window.open(this.presenter.googleTranslateUrl());
    }

    _openCuttingLink() {
        window.open(this.presenter.cuttingUrl());
    }

    _bindShortcuts() {
        let self = this;
        if (!self.showTimeline2) {
          let deleteShortcuts = ['del', '#', 'mod+backspace', 'x'];
          Shortcutter.bindInModule(
            this._shortcutModuleName(),
            deleteShortcuts,
            function () {
              if (self.agentResult.isOwnedByCurrentUser()) {
                  self.agentResult.destroy();
              }
            }
          );
        }
        if (this.presenter.canDownloadResult()) {
            Shortcutter.bindInModule(this._shortcutModuleName(),
                                    'd', function () {
                self._downloadPdf();
            });
        }
        if (this.presenter.needTranslation()) {
            Shortcutter.bindInModule(this._shortcutModuleName(),
                                    'O', function () {
                self._openTranslateLink();
            });
        }

        Shortcutter.bindInModule(this._shortcutModuleName(), 'l', function () {
          if (
            self.agentResult.isOrderable() &&
            self.agentResult.isOrderableOnlineClip()
          ) {
            self._displayOnlineClipOrderModal();
          }
        });
    }

    _unbindShortcuts() {
        Shortcutter.unbindInModule(
            this._shortcutModuleName(),
            ['d', 'O', 'l', 'del', '#', 'mod+backspace', 'x']
        );
    }

    _shortcutModuleName() {
        return 'agents';
    }

    handleReport(type) {
      return () => {
        const clipId = [this.agentResult.get("clip_id")];
        vent.trigger('reporting:new:report', {
          topic_ids: clipId,
          topic_type: 'clip',
          type: type
        });
      };
    }
}

export default AgentResultHeaderActionsController;
