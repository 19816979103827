import _ from 'underscore';
import Clip from 'models/clip';
import Sources from 'collections/sources';
import Comments from 'collections/comments';
import agents from 'collections/agentsInstance';
import users from 'collections/usersInstance';
import Tags from 'collections/tags';
import Logger from 'libraries/logger';
import $ from 'jquery';
import pusherWrapper from 'models/pusherWrapper';
import session from 'models/sessionInstance';
import moment from 'moment';
import EditedAgentResult from 'models/editedAgentResult'

class AgentResult extends Clip {
    parse(response) {
        let data = response.object || response;

        data.created_at = data.created_at * 1000;
        data.updated_at = data.updated_at * 1000;

        this._setAgent(data.agent_id);
        this._updateCommentsCollection(data);
        return _.extend({},
                        this._buildSourcesCollection(data),
                        this._parseMetadata(data),
                        data);
    }
    setPolled() {
        this.polledFlag = true;
    }
    unsetPolled() {
        this.polledFlag = false;
    }
    isPolled() {
        return this.polledFlag === true;
    }
    downloadable() {
        return this.get('downloadable');
    }
    isPrintSource() {
        return this.get('is_print_source');
    }
    wasClipEdited() {
        return this.get('was_clip_edited');
    }

    isOrderable() {
        if (this.isOrdered()) { return false; }
        if (this.isFullRtvClip()) { return false; }
        if (this.isDataLakeClip()) { return true; }
        if (this.isRtvClip()) {
            const clipCreatedAt =
                moment(this.get('clip_created_at') * 1000).startOf('day');
            const startOfToday = moment().startOf('day');
            const unOrderableLimit = 29;
            const difference = startOfToday.diff(clipCreatedAt, 'days');
            const olderThanMonth = difference > unOrderableLimit;

            return !olderThanMonth && !session.rtvMaximumReached();
        } else if (this.isOrderableOnlineClip()) {
            if (this.isOrderableImportedClip()) { return true; }
            return session.hasOnlineArticleLicensing();
        }
    }

    avProcessingTimeout() {
        let clipCreatedAt = moment(this.get('clip_created_at') * 1000);
        let yesterday = moment().add(-1, 'days');

        if (this.isRadioTvClip()) {
            return clipCreatedAt.isBefore(yesterday);
        } else {
            return false;
        }
    }

    getFullText(onSuccess, onFailure) {
        $.ajax({
            url: this.urlRoot + this.get("id") + '/text',
            type: 'GET',
            dataType: 'json',
            context: this,
            success: function (response) {
                onSuccess(response.text, response.rich_text);
            },
            error: function (response) {
                onFailure(response);
            }
        });
    }
  saveTitleFullTextAndAttachments(title, text, richText, attachments, sendingAttachments, sourceData, onSuccess, onFailure) {
    let editedResult = new EditedAgentResult({
      id: this.get('id'),
      title: title,
      text: text,
      rich_text: richText,
      attachments: attachments,
      sourceData: sourceData,
      sending_attachments: sendingAttachments
    })
    editedResult.save(undefined, {
      success: (_model, response) => {
        this.set({
            title: response.agent_result.metadata.title,
            full_text: response.agent_result.metadata.full_text,
            text: response.agent_result.text,
            rich_text: response.agent_result.rich_text,
            teaser: response.agent_result.metadata.teaser,
            clip_attachments: response.agent_result.metadata.clip_attachments,
            primary_source: response.agent_result.metadata.primary_source,
            sources: response.agent_result.metadata.sources
        });

        onSuccess();
      },
      error: onFailure
    })
    }
    isOwnedByCurrentUser() {
        let currentUser = users.get(users.currentUser.id);

        if (session.isGroupAdmin()) {
            return true;
        }

        let agent = agents.get(this.get('agent_id'));

        if (!_.isUndefined(agent)) {
            let userId = agent.get('user_id');
            return currentUser.get('id') === userId;
        } else {
            return false;
        }
    }
    isEditable() {
        return (this.isImportedClip() ||
                this.isMMOClip() ||
                this.isRtvClip() ||
                this.isOrderedOnlineClip() ||
                this.isDpaClip());
    }

    /**
     * The text to show for an article.
     *
     * @returns {String} Either teaser or full text.
     */
    articleText() {
        return this.get('text');
    }

    _setAgent(agent_id) {
        let agent = agents.get(agent_id);

        if (!_.isUndefined(agent)) {
            this.agent = agent;
        } else {
            Logger.error("Agent with id " + agent_id +
                         "not found in Agents collection");
        }
    }
    _buildSourcesCollection(data) {
        let sources = {sources: new Sources(data.metadata.sources)};
        delete data.metadata.sources;
        return sources;
    }
    _updateCommentsCollection(data) {
        if (_.isUndefined(this.comments)) {
            this._createCommentsCollection(data);
        }
        this.comments.set(data.metadata.comments, {
            parse: true
        });
        delete data.metadata.comments;
    }
    _createCommentsCollection(data) {
        this.comments = new Comments(undefined, {
            clip_id: data.metadata.clip_id
        });
        this.listenTo(this.comments, 'add remove', () => {
            this.trigger('change');
        });
    }
    _parseMetadata(response) {
        let clip_id = response.metadata.clip_id;
        delete response.metadata.clip_id;

        let tags = new Tags(response.metadata.tags);
        this.listenTo(tags, 'add remove', () => {
            this.trigger("change");
        });
        delete response.metadata.tags;

        response.metadata.tags = tags;

        let metadata = _.extend({}, response.metadata);
        metadata.clip_id = clip_id;

        delete response.metadata;

        return metadata;
    }
    toJSON() {
        return {
            "agent_result" : {
                "rating_value" : this.get('rating_value'),
                "read" : this.get("read"),
                "hidden" : this.get("hidden"),
                "important": this.get("important")
            }
        };
    }
    sync(method, model, options) {
        if (method === "delete") {
            options.data = 'socket_id=' + pusherWrapper.socketId;
        }
        return Clip.prototype.sync.call(this,
                                        method,
                                        model,
                                        options);
    }
}

AgentResult.prototype.urlRoot = '/api/v3/agent_results/';

export default AgentResult;
