import Marionette from 'backbone.marionette'
import _ from 'underscore'
import CreateSocialMediaMonitorView from 'views/agents/settings/createSocialMediaMonitorView'
import Notificator from 'libraries/notificator'
import Usage from 'models/usage';
import UnitOrderModalView from 'views/settings/unitOrderModalView';
import I18n from 'i18n'

export default class CreateSocialMediaMonitorController extends Marionette.Controller {
    initialize() {
        this.view = new CreateSocialMediaMonitorView({
            model: this.options.socialMediaMonitor
        });
        this._registerListeners();
        this.channel = "none";
    }

    _registerListeners() {
        this.listenTo(this.view, "selected:facebook", () => {
            this.channel = "facebook";
            this.view.updateChannel('facebook');
        });

        this.listenTo(this.view, "selected:facebook_without_comments", () => {
            this.channel = "facebook_without_comments";
            this.view.updateChannel('facebook_without_comments');
        });

        this.listenTo(this.view, "selected:youtube", () => {
            this.channel = "youtube";
            this.view.updateChannel('youtube');
        });

        this.listenTo(this.view, "selected:wikipedia", () => {
            this.channel = "wikipedia";
            this.view.updateChannel('wikipedia');
        });

        this.listenTo(this.view, "deleted:social_media_monitor", (itemView) => {
            itemView.model.destroy();
            this.trigger('deleted:social_media_monitor');
        });

        this.listenTo(this.view, "submitted:form", () => {
            this._buildSocialMediaMonitorModel();
        });

        this.listenTo(this.view, "canceled:form", () => {
            this.view.resetForm();
            this.trigger('social_media_monitor:cancelled');
        });
    }

    _buildSocialMediaMonitorModel() {
        if (this.channel !== "none") {
            const identifier = this.view.identifier();

            this.view.toggleButtons();

            let usage = new Usage()
            usage.load().then(() => {
                if (usage.isMinimumUsageReached('search_topics')) {
                    this._showOrderModal(usage, identifier)
                } else {
                    this._performSave(identifier);
                }
            })
        }
    }

    _showOrderModal(usage, identifier) {
        this.orderModalView = new UnitOrderModalView({
            unitType: 'search_topics',
            usage: usage
        })
        this.listenTo(
            this.orderModalView,
            'modal:confirmed',
            () => this._performSave(identifier)
        );
        this.listenTo(this.orderModalView, 'modal:cancelled', () => this.view.toggleButtons())
        this.view.orderModal.show(this.orderModalView)
        this.orderModalView.showModal()
    }

    onClose() {
        super.onClose()
        if (!_.isUndefined(this.orderModalView)) {
            this.orderModalView.close();
        }
    }

    _performSave(identifier) {
        this.options.agent.get('social_media_monitors').create({
            type: this.channel,
            name: identifier
        }, {
            wait: true,
            parse: false,
            success: () => {
                this.view.toggleButtons();
                this.view.resetForm();
                this.trigger('social_media_monitor:saved');
            },
            error: (model, response) => {
                if (response.status === 409) {
                    const text = response.responseText;
                    if (text.indexOf("Social media account limit exceeded") >= 0) {
                        this.trigger("social_media_monitor:limitReached");
                    } else if (text.indexOf("Global topic/smt limit exceeded") >= 0) {
                        this.trigger("social_media_monitor:globalLimitReached");
                    } else if (text.indexOf("User not found") >= 0) {
                        this.trigger("social_media_monitor:userNotFound");
                    } else if (text.indexOf("Invalid username") >= 0) {
                        this.trigger("social_media_monitor:invalidUsername");
                    }
                } else {
                    const message = I18n.t("webapp.notifications.error.not_saved");
                    Notificator.showNotification(message);
                }
                this.view.toggleButtons();
            }
        });
    }
}
