import Marionette from 'backbone.marionette';
import template from 'text-loader!templates/agents/agentResultHeaderActions.html';
import OnlineClipOrderModalView from 'views/agents/onlineClipOrderModalView';
import RtvOrderNoQuotaModalView from 'views/agents/rtvOrderNoQuotaModalView';
import RtvOrderModalView from 'views/agents/rtvOrderModalView';
import _ from 'underscore';
import session from 'models/sessionInstance';
import SearchableDropdown from 'components/SearchableDropdown';
import * as React from 'react';
import ReactWrapper from 'views/marionetteReactWrapper';
import AddToReaderClient from 'components/addToReaderClient';
import SendEmailModal from '../../components/SendEmailModal';
import ShowClipIdHeader from '../../components/ShowClipIdHeader';

class AgentResultHeaderActionsView extends Marionette.Layout {
    serializeData() {
        let presenter = this.options.presenter;
        const canCreateReport = session.get('features').reports_select_clips_individually;
        const reportingModuleAvailable = session.hasModule('reports')
        const isAgentResult = this._modelIsAgentResult();
        const clipDownloadEnabled = session.hasFeature('clip_download_enabled')
        return {
            downloadable: presenter.canDownloadResult(),
            isTranslatable: presenter.needTranslation(),
            isOwnedAgentResult: this.model.isOwnedByCurrentUser(),
            isOrderableOnlineClip: this.model.isOrderableOnlineClip(),
            isOrderable: this.model.isOrderable(),
            isOrdered: this.model.isOrdered(),
            isEditable: this._isAgentResultEditable(),
            isClipEditable: this._isClipEditable(),
            isCuttable: this.model.isCuttable(),
            alanJobId: this.model.id,
            hasPdfExport: session.get("features").reports_pdf_export,
            hasExcelExport: session.get("features").reports_excel_export,
            hasNewsletterReport: session.get("features").reports_newsletter,
            canCreateReport: canCreateReport && isAgentResult && reportingModuleAvailable,
            clipDownloadEnabled: clipDownloadEnabled,
            canBeAddedToReader: session.hasFeature('use_new_reader_management'),
            canShareArticleViaEmail: session.shareSelectedArticlesViaEmail(),
        };
    }

    _modelIsAgentResult() {
        // in research it is a clip
        return !!this.model.get('clip_id');
    }

    onRender() {
        this.delegateEvents();
        if (session.hasFeature('use_new_reader_management')) {
          const props = {
            onCategorySelect: (confId, catId) => {
                new AddToReaderClient(confId, catId).fromAgentResults(
                    [this.model.get('id')]
                );
            }
          };
          let component = <SearchableDropdown {...props} />
            let componentWrapper = new ReactWrapper({
                tagName: 'span',
                getComponent: () => component
            })
            this.searchableDropdown.show(componentWrapper);
        }
        if (session.shareSelectedArticlesViaEmail()) {
            this.showArticleViaEmailModal();
        }
        if (session.isImpersonated() || session.can('internal_access')) {
            this.showClipId();
        }
    }

    onClose() {
        if (!_.isUndefined(this.noQuotaModalView)) {
            this.noQuotaModalView.close();
        }

        if (!_.isUndefined(this.onlineClipOrderView)) {
            this.onlineClipOrderView.close();
        }
    }

    showRtvOrderModal(upgradePrice) {
        this.orderModalView = new RtvOrderModalView(
            { upgradePrice: upgradePrice }
        );
        const self = this;
        this.listenTo(
            this.orderModalView,
            'modal:confirmed',
            function (notes) { self.handleOrderConfirmation(notes) }
        );
        this.orderModal.show(this.orderModalView);
        this.orderModalView.showModal();
    }

    showOnlineClipOrderModal() {
        this.onlineClipOrderView = new OnlineClipOrderModalView();
        const self = this;
        this.listenTo(
            this.onlineClipOrderView,
            'modal:confirmed',
            function (notes) { self.handleOrderConfirmation(notes) }
        );
        this.orderModal.show(this.onlineClipOrderView);
        this.onlineClipOrderView.showModal();
    }

    showNoquotaModal() {
        this.noQuotaModalView = new RtvOrderNoQuotaModalView();
        this.noQuotaModal.show(this.noQuotaModalView);
        this.noQuotaModalView.showModal();
    }

    handleOrderConfirmation(notes) {
        if (this.model.isRtvClip()) {
            this.orderModalView.closeModal();
            this.orderModalView.close();
            this.trigger('modal:rtvOrderConfirm', notes);
        } else if (this.model.isOrderableOnlineClip()) {
            this.trigger('modal:onlineClipOrderConfirm');
        }
    }

    _handleOrderClick() {
        if (this.model.isRtvClip()) {
            this.trigger('click:rtvOrder');
        } else if (this.model.isOrderableOnlineClip()) {
            this.trigger('click:onlineOrder');
        }
    }

    _isAgentResultEditable() {
        return session.hasFeature('agent_result_editing_possible') &&
          this.model.isEditable()
    }

    _isClipEditable() {
        return session.can('edit_clips_globally')
    }

    showArticleViaEmailModal() {
        let component = <SendEmailModal emailAgentResultIds={[this.model.get("id")]} emailClipIds={[]}/>
            let componentWrapper = new ReactWrapper({
                getComponent: () => component
            })
        this.articleViaEmail.show(componentWrapper);
    }

    showClipId() {
        let component = <ShowClipIdHeader clipId={this.model.get("clip_id")} agentResultId={this.model.get("id")}/>
            let componentWrapper = new ReactWrapper({
            getComponent: () => component
            })
        this.clipId.show(componentWrapper);
    }
}

AgentResultHeaderActionsView.prototype.events = {
    'click [data-clip-order]': '_handleOrderClick',
};

AgentResultHeaderActionsView.prototype.triggers = {
    'click #clip-download': 'clicked:download',
    'click [data-result-delete]': 'click:deleteResult',
    'click [data-result-translate]': 'click:translate',
    'click [data-result-cut]': 'click:cut',
    'click [data-result-edit]': 'click:editAgentResult',
    'click [data-clip-edit]': 'click:editClip',
    'click [data-pdf-export-button]': 'clicked:pdf:report',
    'click [data-excel-export-button]': 'clicked:excel:report',
    'click [data-newsletter-report-button]': 'clicked:newsletter:report',
};

AgentResultHeaderActionsView.prototype.regions = {
    orderModal: '[data-order-modal]',
    noQuotaModal: '[data-noquota-modal]',
    orderButton: '[data-clip-order]',
    searchableDropdown: '[data-add-to-reader]',
    articleViaEmail: '[data-article-email]',
    clipId: '[data-show-clip-id]'
};

AgentResultHeaderActionsView.prototype.template = template;

export default AgentResultHeaderActionsView;
